import {useAccountPoolQuery} from 'api';
import moment from 'moment';
import React from 'react';
import {useParams} from 'react-router-dom';

export default () => {
  const {accountId} = useParams<{
    accountId: string;
  }>();

  const {data: {accountPool: {recentAccountSignals = []} = {}} = {}} =
    useAccountPoolQuery({variables: {uuid: accountId}});

  return (
    <div className="bg-white border border-c-border rounded">
      <h2 className="h-10 flex items-center border-b border-c-border text-sm px-4 font-bold text-c-light">
        シグナル
      </h2>
      <div className="p-4 flex flex-col gap-3">
        {recentAccountSignals.map((signal) => (
          <div key={signal.id} className="flex items-center gap-4">
            <span className="text-gray-700">
              {moment(signal.date).format('YYYY/MM/DD')}
            </span>
            <span className="px-2.5 py-1 bg-c-bg rounded text-sm leading-[17px] text-c-base whitespace-nowrap">
              {signal.signalableClass == 'Page::Prtime' && 'メディア'}
            </span>
            <span className="truncate w-[720px]">
              <a href={signal.signalable.url} target="_blank">
                {signal.signalable.title || signal.signalable.url}
              </a>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
