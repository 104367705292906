import gql from 'graphql-tag';
import {pressReleaseFragment} from '.';

export default gql`
  fragment accountSignalFragment on AccountSignal {
    id
    signalableClass
    date
    signalable {
      ... on Prtime {
        ...pressReleaseFragment
      }
    }
  }
  ${pressReleaseFragment}
`;
