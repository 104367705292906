import React from 'react';
import StatusSelect from './StatusSelect';
import {PencilSquareIcon} from '@heroicons/react/20/solid';
import UserSelect from './UserSelect';
import Category from './Category';

export default () => {
  return (
    <div className="w-96">
      <div className="bg-white border border-c-border rounded">
        <h2 className="h-10 flex items-center border-b border-c-border text-sm px-4 font-bold text-c-light">
          基本情報
        </h2>
        <div className="p-4">
          <div className="flex flex-col text-sm gap-2">
            <StatusSelect />
            <UserSelect />
            <div className="flex items-center justify-between">
              <div>
                <div className="text-c-light">タグ</div>
                <div className="flex gap-2">
                  <span className="px-3 py-1 rounded text-sm leading-none font-bold tracking-tight text-white bg-[#48938A]">
                    Hubspot導入企業
                  </span>
                  <span className="px-3 py-1 rounded text-sm leading-none font-bold tracking-tight text-white bg-[#E5613D]">
                    Salesforce導入企業
                  </span>
                </div>
              </div>
              <button className="cursor-pointer bg-transparent border-none outline-none flex items-center">
                <PencilSquareIcon className="w-5 h-5 text-c-primary" />
              </button>
            </div>
            <div className="border-b border-c-border p-2" />
            <Category />
          </div>
        </div>
      </div>
    </div>
  );
};
