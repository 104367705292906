import React from 'react';
import {useAccountPoolQuery} from 'api';
import moment from 'moment';
import {useParams, Link} from 'react-router-dom';
import {Salesforce, Tel, Task, Mail} from 'components/Ui/Icon';

export default () => {
  const {poolId, accountId} = useParams<{
    poolId: string;
    accountId: string;
  }>();

  const {data: {accountPool: {activeProspectPools = []} = {}} = {}} =
    useAccountPoolQuery({variables: {uuid: accountId}});

  return (
    <div className="bg-white border border-c-border rounded">
      <h2 className="h-10 flex items-center border-b border-c-border text-sm px-4 font-bold text-c-light">
        リード
      </h2>
      <table className="w-full text-sm text-left">
        <thead className="text-xs border-b border-c-border text-c-light">
          <tr>
            <th scope="col" className="px-4 py-1">
              姓名
            </th>
            <th scope="col" className="px-4 py-1">
              ステージ
            </th>
            <th scope="col" className="px-4 py-1">
              役職
            </th>
            <th scope="col" className="px-4 py-1">
              Salesforce
            </th>
            <th scope="col" className="px-4 py-1">
              最終アプローチ
            </th>
            <th scope="col" className="px-4 py-1">
              担当者
            </th>
          </tr>
        </thead>
        <tbody>
          {activeProspectPools.map((prospectPool, i) => (
            <tr className="border-b border-c-border" key={i}>
              <th
                scope="row"
                className="px-4 py-2 font-normal whitespace-nowrap">
                <Link
                  to={`/pools/${poolId}/prospects/${prospectPool.uuid}`}
                  target="_blank">
                  {prospectPool.prospect.lastName}{' '}
                  {prospectPool.prospect.firstName}
                </Link>
              </th>
              <td className="px-4">
                <span className="px-3 py-1 rounded-full text-xs leading-none font-bold tracking-tight bg-[#DCEEFF] text-[#005388]">
                  {prospectPool.stageText}
                </span>
              </td>
              <td className="px-4 py-2">
                {prospectPool.prospect.sectionPosition}
              </td>
              <td className="px-4 py-2">
                <div className="flex w-max items-center gap-2 [&>svg]:w-4">
                  {(prospectPool.salesforceContact ||
                    prospectPool.salesforceLead) && <Salesforce />}
                  <div className="flex items-center gap-1">
                    {prospectPool.salesforceDeals?.length > 0 && (
                      <span className="flex px-2 h-5 items-center gap-1 rounded-sm border-none bg-[#DCEEFF] text-xs font-bold text-[#005388]">
                        商談
                        <span className="flex h-3 items-center rounded-full bg-[#C7E0EF] px-1 text-xs font-bold text-[#005388]">
                          {prospectPool.salesforceDeals?.length}
                        </span>
                      </span>
                    )}
                    {prospectPool.salesforceContact && (
                      <span className="flex px-2 h-5 items-center gap-1 rounded-sm border-none bg-[#DCEEFF] text-xs font-bold text-[#005388]">
                        取引先責任者
                      </span>
                    )}
                    {prospectPool.salesforceLead && (
                      <span className="flex px-2 h-5 items-center gap-1 rounded-sm border-none bg-[#DCEEFF] text-xs font-bold text-[#005388]">
                        リード
                      </span>
                    )}
                  </div>
                </div>
              </td>
              <td className="px-4 py-2"></td>
              <td className="px-4 py-2">
                {prospectPool.user?.lastName} {prospectPool.user?.firstName}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="px-4">
        <div className="text-right py-2">
          <a>もっとみる</a>
        </div>
      </div>
    </div>
  );
};
