import {ProspectPool} from 'api';
import React from 'react';
import {Row} from 'react-table';
import {Salesforce} from 'components/Ui/Icon';
import {Link, useParams} from 'react-router-dom';

interface Props {
  row: Row<ProspectPool>;
}

const ProspectName = ({row}: Props) => {
  const prospectPool = row.original;
  const {poolId} = useParams<{poolId: string}>();

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-2">
        <Link
          to={`/pools/${poolId}/prospects/${prospectPool.uuid}`}
          className="m-0 text-base leading-none truncate"
          target="_blank">
          {[prospectPool.prospect?.lastName, prospectPool.prospect?.firstName]
            .filter(Boolean)
            .join(' ')}
        </Link>
        {(prospectPool.salesforceContact || prospectPool.salesforceLead) && (
          <Salesforce className="w-4 h-4" />
        )}
        {prospectPool.salesforceContact && (
          <span className="px-2 leading-5 rounded-sm text-xs font-bold tracking-tight bg-[#DCEEFF] text-[#005388]">
            取引先責任者
          </span>
        )}
        {prospectPool.salesforceLead && (
          <span className="px-2 leading-5 rounded-sm text-xs font-bold tracking-tight bg-[#DCEEFF] text-[#005388]">
            リード
          </span>
        )}

        {prospectPool?.isUnsubscribed && (
          <span className="px-2 leading-5 text-xs font-bold tracking-tight text-[#95002D] bg-[#FBEDED]">
            配信停止
          </span>
        )}
      </div>
      <span className="text-xs text-c-light truncate">
        {prospectPool.prospect?.email}
      </span>
    </div>
  );
};

export default ProspectName;
