import React from 'react';
import {useAccountPoolQuery} from 'api';
import {useParams} from 'react-router-dom';

export default () => {
  const {accountId} = useParams<{accountId: string}>();
  const {data: {accountPool: {account = {}} = {}, accountPool = {}} = {}} =
    useAccountPoolQuery({
      variables: {uuid: accountId},
      skip: !accountId,
    });
  return (
    <header className="flex flex-col gap-2 items-start my-6">
      {accountPool?.clientAccountStatus && (
        <span className="px-3 py-1 rounded-full text-xs leading-none font-bold tracking-tight bg-[#DCEEFF] text-[#005388]">
          {accountPool.clientAccountStatus.name}
        </span>
      )}
      <h1 className="text-xl font-bold m-0 leading-none">{account?.name}</h1>
      {account?.webSite && (
        <a className="leading-none" href={account.webSite} target="_blank">
          {account.webSite}
        </a>
      )}
    </header>
  );
};
